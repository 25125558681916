

.bottombar{
    position: fixed;
    bottom: 0px;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      border-top:3px solid rgb(231, 233, 251);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      
    
      width: 100%;
      height: 65px;
      padding-top: 5px;
    
      /* margin: 0%; */
      margin-top: 1px;
      font-size: small;
      background-color: rgb(10,13,37);
    
    }