.hello{
    width: 98%;
    height: 50px;
   color: rgb(32, 84, 131);
   /* border: 1px solid rgba(1, 1, 102, 0.141); */
   /* border-radius: 5px; */
  
  
   padding: 0px;
   /* margin: 10px; */
   margin-right: 15px;
   
  
   
   /* box-shadow: 3px 2px 5px 2px #e9ebfa; */
  
  }