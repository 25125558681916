.topBar{
    /* font-size: large; */
    /* margin-left: 10px; */
    text-align: center;
}
.searchBar{
    text-align: center;
    color: aqua;
    /* background-color: blue; */
    /* width: 100%; */
    height: auto;
    margin-left: 20px;
    /* margin-right: 30px; */
    width: calc(100% - 40px);
}
.boxFront{
    width: 35%;
    height: 100px;
   color: rgb(255, 255, 255);
   /* border: 1px solid; */
   border-radius: 17px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   
   box-shadow: 2px 1px 7px #7a7979;
   font-size: 12px;
  
}
.todaysActivity{
    margin:10px
    

    /* position:absolute; */
}
.todayActivity_Box{
    width: 100%;
    height: 80px;
   color: rgb(32, 84, 131);
  margin-top: 10px;
   border-radius: 5px;
     border: 1px solid rgba(218, 218, 251, 0.403);
  
   
   box-shadow: 1px 2px  #000d5e4d;
   display: flex;
   /* flex-direction: column; */
   justify-content: space-between;
   /* padding-left : 10px; */
   /* align-items: center; */

}